import { useContext, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import FormGroup from "../form/FormGroup";
import TrashIcon from "../../icons/TrashIcon";

function EditProductPopup() {
	const { cart, setCart, editingProduct, setEditingProduct, stock, setEdited, setConfirm } =
		useContext(AppContext);
	const flows = [1, 2, 3, 4, 5];

	const setFlow = (flow) => {
		let nCart = { ...cart };
		nCart.products[editingProduct.i].flow = flow;
		setCart(nCart);
	};

	const subQty = (i) => {
		let nCart = { ...cart };
		if (nCart.products[i].qty > 1) nCart.products[i].qty--;
		setCart(nCart);
		setEdited(true);
	};

	const addQty = (i) => {
		let nCart = { ...cart };
		const s = stock.find((s) => s.product_id == nCart.products[i].id);
		if (s === undefined || nCart.products[i].qty < s.qty) {
			nCart.products[i].qty++;
			setCart(nCart);
			setEdited(true);
		}
	};

	const remove = (i) => {
		setConfirm({
			title: "Sei sicuro?",
			message: "",
			onConfirm: async () => {
				let nCart = { ...cart };
				nCart.products.splice(i, 1);
				setCart(nCart);
				setEdited(true);
				setEditingProduct(false);
			},
		});
	};

	const setNotes = (notes) => {
		let nCart = { ...cart };
		nCart.products[editingProduct.i].notes = notes;
		setCart(nCart);
	};

	return (
		<PopUp
			size=""
			options={{
				onClose: false,
				title: editingProduct.data.name,
				subtitle: "",
				headerButtons: (
					<button onClick={() => remove(editingProduct.i)}>
						<TrashIcon />
					</button>
				),
			}}
		>
			<div className="inner-content">
				<h4>Quantità:</h4>
				<div className="plus-minus-generic">
					<button onClick={() => subQty(editingProduct.i)}>-</button>
					<div>{editingProduct.data.qty}</div>
					<button onClick={() => addQty(editingProduct.i)}>+</button>
				</div>
				<h4>Portata:</h4>
				<div className="cart-flows-buttons">
					{flows.map((el, i) => (
						<button
							key={i}
							className={cart.products[editingProduct.i].flow == el ? "selected" : ""}
							onClick={() => setFlow(el)}
						>
							{"Portata " + el}
						</button>
					))}
				</div>
				<h4>Note prodotto:</h4>
				<FormGroup
					type="textarea"
					value={cart.products[editingProduct.i].notes}
					onChange={(e) => {
						setNotes(e.target.value);
					}}
				/>
			</div>
			<div className="popup-footer">
				<div className="buttons-group">
					<button className="button button-2" onClick={() => setEditingProduct(false)}>
						Chiudi
					</button>
				</div>
			</div>
		</PopUp>
	);
}

export default EditProductPopup;
